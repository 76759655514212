import { getCookie } from 'cookies-next';
import { useMemo } from 'react';
import { FEATURE_FLAG_CONFIGURATIONS } from './feature-flags';
import type { FeatureFlag } from './types';

export const isFeatureEnabled = (featureFlag: FeatureFlag) => {
  const customerId = getCookie('customer_id') as string;
  const configuration = FEATURE_FLAG_CONFIGURATIONS[featureFlag];

  if (!configuration) {
    return true;
  }
  const level = process.env.NEXT_PUBLIC_CLIENT_TYPE as 'local' | 'dev' | 'prod';
  if (configuration[level] === true) {
    if (configuration.overrideDisabledForCustomerIds && customerId) {
      return !configuration.overrideDisabledForCustomerIds.includes(customerId);
    }
    return true;
  }

  if (configuration.overrideEnabledForCustomerIds && customerId) {
    const isEnabledForCustomer =
      configuration.overrideEnabledForCustomerIds.includes(customerId);
    return isEnabledForCustomer;
  }

  return false;
};

export const useFeatureFlag = (featureFlag: FeatureFlag) => {
  const enabled = useMemo(() => {
    return isFeatureEnabled(featureFlag);
  }, [featureFlag]);

  return enabled;
};
