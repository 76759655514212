import { FEATURE_FLAGS } from './constants';
import type { FeatureFlag, FeatureFlagConfiguration } from './types';

export const FEATURE_FLAG_CONFIGURATIONS: Record<
  FeatureFlag,
  FeatureFlagConfiguration
> = {
  [FEATURE_FLAGS.TF_ERRANDS]: {
    prod: false,
    dev: false,
    local: true,
    overrideEnabledForCustomerIds: [
      '766b56fe-1b83-43ce-b3d7-bc6bd64a238c', // bataljonen
      'ce9f9d02-19f3-49a7-b322-4a996b421ebe', // CX-miljö
      '1f7322ad-9635-4b99-90e4-ac3aa2483d1f', // UH
      '540581d9-b884-491d-8ce6-ed8b42c9f8f9', // Stena
    ],
  },
  [FEATURE_FLAGS.TF_INSPECTIONS]: {
    prod: false,
    dev: false,
    local: true,
  },
  [FEATURE_FLAGS.TF_CHAT]: {
    prod: false,
    dev: false,
    local: true,
  },
  [FEATURE_FLAGS.VAT_REPORT]: {
    prod: false,
    dev: false,
    local: true,
    overrideEnabledForCustomerIds: [
      '766b56fe-1b83-43ce-b3d7-bc6bd64a238c',
      'ce9f9d02-19f3-49a7-b322-4a996b421ebe',
    ],
  },
  [FEATURE_FLAGS.BRF_REPORTS]: {
    prod: false,
    dev: false,
    local: true,
    overrideEnabledForCustomerIds: [
      '766b56fe-1b83-43ce-b3d7-bc6bd64a238c',
      'ce9f9d02-19f3-49a7-b322-4a996b421ebe',
    ],
  },
  [FEATURE_FLAGS.PAYMENT_REPORTS]: {
    prod: false,
    dev: false,
    local: true,
    overrideEnabledForCustomerIds: [
      '766b56fe-1b83-43ce-b3d7-bc6bd64a238c',
      'ce9f9d02-19f3-49a7-b322-4a996b421ebe',
    ],
  },
} as const;
