export const FEATURE_FLAGS = {
  TF_INSPECTIONS: 'TF_INSPECTIONS',
  TF_ERRANDS: 'TF_ERRANDS',
  TF_CHAT: 'TF_CHAT',
  VAT_REPORT: 'VAT_REPORT',
  BRF_REPORTS: 'BRF_REPORTS',
  PAYMENT_REPORTS: 'PAYMENT_REPORTS',
} as const;

export const LEVELS = ['prod', 'dev', 'local'] as const;
