'use client';

import { useGetOrganization } from '@/config/sales/organization/client';
import { useGetCookies } from '@/hooks/use-get-cookies';
import { useGetSelf } from '@/hooks/useGetSelf';
import { BASE_BACKEND_URL } from '@/requests/constants';
import type { Self } from '@/store/types';
import Intercom from '@intercom/messenger-js-sdk';
import type { Organization } from '@pigello/pigello-matrix';
import { DateTime } from 'luxon';

function IntercomInit({
  self,
  organization,
}: {
  self: Self;
  organization: Organization;
}) {
  const { cookies } = useGetCookies();

  Intercom({
    app_id: 'ilbv7ji4',
    region: 'eu',
    user_id: self.id,
    name: self.communicationName,
    username: self.username,
    email_verified: self.emailVerified,
    phone_verified: self.phoneNumberVerified,
    detail_access: self.detailAccess,
    email: self.email,
    created_at: DateTime.fromISO(self.createdAt).toUnixInteger(),
    company: {
      company_id: cookies?.customer_id,
      name: organization.name,
    },
    phone: self?.phoneNumber,
    avatar: {
      type: 'avatar',
      image_url: self?.profilePicture?.get,
    },
    hide_default_launcher: true,
  });

  return <></>;
}

const INTERCOM_DEMO_CUSTOMER_IDS = [
  '72583069-f587-4cbe-8004-a16d7f77b76b',
  'eae8814b-3282-49b5-b9e5-c40e495825e6',
];
export const getIsTrustedCustomer = (customer_id: string | undefined) =>
  !!customer_id && INTERCOM_DEMO_CUSTOMER_IDS.includes(customer_id);

export default function IntercomWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data: self } = useGetSelf();
  const { cookies } = useGetCookies();
  const isTrustedCustomer = getIsTrustedCustomer(cookies?.customer_id);

  const { data: mainOrg } = useGetOrganization({
    id: self?.organization.id,
    enabled: !!self,
  });

  const isDev = BASE_BACKEND_URL?.includes('dev.api');

  return (
    <>
      {self && mainOrg && (!isDev || isTrustedCustomer) && (
        <IntercomInit organization={mainOrg} self={self}></IntercomInit>
      )}
      {children}
    </>
  );
}
